import React, { useContext } from 'react'
import Link from 'next/link'
import { imgApiUrl, BASE_URL } from '../../lib/constants'
import { MainContext } from '../../contexts/MainContext'
import Image from 'next/dist/client/image'

const ProductCarousel = (props: any) => {
	const { setIsLoading } = useContext(MainContext)
	const apiDate = props?.recommended_products?.price_discounted_end

	// Extracting prices from props
	const catalogPrice = props?.recommended_products?.price_catalog
	const discountedPrice = props?.recommended_products?.price_discounted

	// Calculating the discount percentage
	const discountPercentage = ((catalogPrice - discountedPrice) / catalogPrice) * 100

	const discountedEndDate = apiDate ? new Date(apiDate) : null
	const Dis_percent = ((props?.recommended_products?.price_catalog - props?.recommended_products?.price_discounted) / props?.recommended_products?.price_catalog) * 100
	const price =
		props?.recommended_products?.price_discounted != null && props?.recommended_products?.price_discounted != 0 && (discountedEndDate === null || discountedEndDate >= new Date()) ? (
			<>
				<span className='old-price mr-2 text-[15px] text-[#999999] line-through'>${props?.recommended_products?.price_catalog?.toFixed(2)}</span>
				<span className='new-price mr-2 text-[17px] font-bold'>${props?.recommended_products?.price_discounted?.toFixed(2)}</span>
				{/* <span className='old-price text-xs text-red-400 font-bold'>{Dis_percent?.toFixed(2)}% Off</span> */}
			</>
		) : props?.recommended_products?.price < props?.recommended_products?.price_catalog ? (
			<span className='new-price mr-2 font-bold text-[17px]'>${props?.recommended_products?.price?.toFixed(2)}</span>
		) : (
			// <span className='new-price mr-2 font-bold text-[17px]'>${props.recommended_products?.price_catalog?.toFixed(2)}</span>
			<span className='new-price mr-2 font-bold text-[17px]'>{props?.recommended_products?.price_catalog ? `$${props?.recommended_products?.price_catalog?.toFixed(2)}` : 'Multiple SKUs'}</span>
		)
	return (
		<div className='mx-2'>
			<div className='related-product-wrapper bg-gray-100 p-4 rounded-lg w-full h-full relative'>
				<div className='related-product-img rounded-lg w-full relative'>
					{/* <div className='sale-notify-special absolute top-0 left-0 uppercase text-xs dark-blue-bg w-max text-white  px-2 py-1 tracking-widest z-10'>{Dis_percent?.toFixed(2)}% Off</div> */}
					{props?.recommended_products?.price_discounted != '' && props?.recommended_products?.price_discounted != 0 && props?.recommended_products?.price_discounted != null ? (
						<>
							<div className='sale-notify absolute top-0 left-0  uppercase text-xs dark-blue-bg w-max text-white  px-2 py-1 tracking-widest z-10'>{discountPercentage?.toFixed(2)}% Off</div>
						</>
					) : (
						''
					)}
					<div className='p-6 bg-white'>
						<Link href={`/${props?.recommended_products?.slug}`}>
							<a onClick={() => setIsLoading(true)}>
								<Image
									layout='responsive'
									width={263}
									height={263}
									className='related-product-img rounded-lg w-full'
									src={props?.recommended_products?.image != null ? `${imgApiUrl?.products?.medium}/${props?.recommended_products?.image}` : `${BASE_URL}/img/image.jpg`}
									blurDataURL={props?.recommended_products?.image != null ? `${imgApiUrl?.products?.medium}/${props?.recommended_products?.image}` : `${BASE_URL}/img/image.jpg`}
									alt={props?.recommended_products?.name}
									lazyBoundary='400px'
								/>
							</a>
						</Link>
					</div>
				</div>
				<div className='related-product-detail-wrapper'>
					<Link href={`/${props?.recommended_products?.slug}`}>
						<a onClick={() => setIsLoading(true)}>
							<div className='related-product-detail dark-blue-color text-sm mt-2 flex items-center h-10'>{`${props?.recommended_products?.name?.substring(0, 50)} ${props?.recommended_products?.name?.length > 50 ? '...' : ''}`}</div>
							<div className='mt-1'>{price}</div>
						</a>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default ProductCarousel
