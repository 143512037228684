import { RefObject, useContext, useEffect, useRef, useState } from 'react'
import { API_BASE_URL } from '../../lib/constants'
import Style from '../Page/Details.module.css'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { LiteLoader } from '../CustomElements/Loader/Loader'
import { MainContext } from '../../contexts/MainContext'
import Payment from '../../public/assets/img/Payment/payment.jpg'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Breadcrumb from '../../UIComponents/Breadcrumb'
import MetaTags from '../MetaTags/MetaTags'
interface pageProps {
	heading: string
	content: string
}

interface pageDetail {
	page: pageProps
	slug: boolean
	setPageDetail: React.Dispatch<React.SetStateAction<{}>>
}

const Detail = (props: any) => {
	const [pageDetail, setPageDetail] = useState<{}>({})
	const [isVerified, setIsVerified] = useState(false)
	const [isVmessage, setIsVmessage] = useState(false)
	const recaptcha: RefObject<ReCAPTCHA> = useRef(null)
	const [successMessage, setSuccessMessage] = useState({
		type: '',
		message: ''
	})
	useEffect(() => {
		const getPageDetail = async () => {
			const result = await fetch(`${API_BASE_URL}${props?.slug}`)
			const res = await result?.json()
			setPageDetail(res)
		}
		getPageDetail()
	}, [props])

	const router = useRouter()

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm()

	function onChangeCaptcha(value) {
		setIsVerified(true)
		setIsVmessage(false)
		// if (token) {
		// 	formData.captchaToken = token;
		//   }
	}
	/// api form ///
	const onSubmit = async (data) => {
		if (!isVerified) {
			setIsVmessage(true)

			return
		}
		setLoading(true)
		try {
			const response = await axios.post(`${API_BASE_URL}shipping-return`, data)

			reset()
			recaptcha?.current?.reset()

			if (response?.data != '') {
				setSuccessMessage({ type: 'success', message: response?.data?.response })
				setTimeout(() => {
					setSuccessMessage(null)
				}, 4000)
			}
			setLoading(false)
		} catch (error) {
			console.error('Error submitting form:', error)
			setLoading(false)
		}
	}
	const phoneFormatHanlder = (e) => {
		const input = e.target.value.replace(/\D/g, '')
		let formattedPhone = input
		if (input.length >= 9) {
			formattedPhone = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`
		}
		e.target.value = formattedPhone
	}
	const [Loading, setLoading] = useState(false)
	const cursor = Loading ? 'cursor-not-allowed' : 'cursor-pointer'

	const { setIsLoading } = useContext(MainContext)

	useEffect(() => {
		setIsLoading(false)
	}, [setIsLoading])

	console.log(props.page.meta_description)

	return (
		<>
			<MetaTags title={props?.page?.meta_title} description={props?.page?.meta_description} keywords={props?.page?.meta_keywords} />
			<Breadcrumb data={props?.page} />
			<div className='relative D_pages_wraper'>
				<div className='checkout-page-container width mt-7 md:mt-14 min-h-[400px]'>
					<h1 className='text-3xl lg:text-5xl sm:text-3xl font-bold'>{props?.page != undefined && props?.page?.heading}</h1>
					<div className='returning-customer rounded-lg my-4 mb-5 md:mb-14 leading-normal  '>
						<div className='grid grid-cols-1 w-full gap-4 all-instruments-links'>
							<div className={Style.detail} dangerouslySetInnerHTML={{ __html: props?.page != undefined ? props?.page?.content : '' }}></div>
						</div>
						{router.asPath === '/payment-info' && (
							<div className=' paymentImgfooter '>
								<Image src={Payment} alt='payment' width={282} height={44} />
							</div>
						)}
					</div>
					{props?.slug[0] === 'returns-refunds-and-exchanges' ? (
						<section className='returnForm'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 font-bold leading-normal'>Your Contact Information</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4 '>
									<label className='relative block font-semibold leading-normal' htmlFor='title'>
										Title:
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='title' name='title' placeholder='Please select ...' {...register('title')}>
										<option value=''>Please select ...</option>
										<option>Dr.</option>
										<option>Mrs.</option>
										<option>Mr.</option>
										<option>Ms.</option>
										<option>Sir</option>
									</select>
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4 relative'>
									<label className='relative block font-semibold leading-normal' htmlFor='first_name'>
										First Name: <span className='text-red-500'>*</span>
									</label>
									<input
										type='text'
										className={`w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none ${errors?.first_name ? 'border-red-400 focus:border-red-400' : 'border-gray-300'}`}
										placeholder='Enter your first name'
										{...register('first_name', { required: true })}
									/>
									{errors?.first_name && <p className='absolute right-4 text-red-500'>required</p>}
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4 relative'>
									<label className='relative block font-semibold leading-normal' htmlFor='last_name'>
										Last Name: <span className='text-red-500'>*</span>
									</label>
									<input type='text' className={`w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none ${errors?.last_name ? 'border-red-400 focus:border-red-400' : 'border-gray-300'}`} placeholder='Enter your last name' {...register('last_name', { required: true })} />
									{errors?.last_name && <p className='absolute right-4 text-red-500'>required</p>}
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='position'>
										Position:
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='position' name='position' placeholder='Please select ...' {...register('position')}>
										<option value=''>Please select ...</option>
										<option value='Primary Investigator'>Primary Investigator</option>
										<option value='Research Scientist'>Research Scientist</option>
										<option value='Graduate Student'>Graduate Student</option>
										<option value='Student'>Student</option>
										<option value='Lab Technician'>Lab Technician</option>
										<option value='Lab Manager'>Lab Manager</option>
										<option value='Purchasing'>Purchasing</option>
										<option value='None of the above'>None of the above</option>
									</select>
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='institution'>
										Institution:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='institution' name='institution' placeholder='Enter your Institution' {...register('institution')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='department'>
										Department:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='department' name='department' placeholder='Enter your department' {...register('department')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4 relative'>
									<label className='relative block font-semibold leading-normal' htmlFor='phone'>
										Phone:
									</label>
									<input
										type='tel'
										className={`w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none ${errors?.phone && errors?.phone?.type ? 'border-red-400 focus:border-red-400' : 'border-gray-300'}`}
										id='phone'
										name='phone'
										placeholder='Enter your phone'
										{...register('phone', {
											minLength: 14,
											maxLength: 15,
											onChange: (e) => {
												phoneFormatHanlder(e)
											}
										})}
									/>
									{errors?.phone && errors?.phone?.type === 'minLength' && <p className='absolute right-4 text-red-500'>Min 10 digits</p>}
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='fax'>
										Fax:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='fax' name='fax' placeholder='Enter your fax' {...register('fax')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4 relative'>
									<label className='relative block font-semibold leading-normal' htmlFor='email'>
										Email: <span className='text-red-500'>*</span>
									</label>
									<input
										type='email'
										className={`w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none ${errors?.email && errors?.email?.type ? 'border-red-400 focus:border-red-400' : 'border-gray-300'}`}
										placeholder='Enter your email'
										{...register('email', {
											required: true,
											pattern: {
												value: /^[A-Z0-9.]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i,
												message: 'invalid email'
											}
										})}
									/>
									{errors?.email && errors?.email?.type === 'required' && <p className='absolute right-4 text-red-500'>required</p>}
									{errors?.email && errors?.email?.type === 'pattern' && <p className='absolute right-4 text-red-500'>Invalid</p>}
								</div>

								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 mt-3 font-bold leading-normal'>Shipping Address for Repaired/Returned Product</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='address'>
										Shipping Address:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='address' name='address' placeholder='Enter your address' {...register('address')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='address2'>
										Shipping Address 2:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='address2' name='address2' placeholder='Enter your address line 2' {...register('address2')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='city'>
										Shipping City:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='city' name='city' placeholder='Enter your city' {...register('city')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='state'>
										Shipping State (for customers in the USA or PR):
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='state' name='state' placeholder='Please select ...' {...register('state')}>
										<option value=''>Please select ...</option>
										<option value='Other'>Other</option>
										<option value='None'>None</option>
										<option value='Alaska'>Alaska</option>
										<option value='Alberta'>Alberta</option>
										<option value='Arizona'>Arizona</option>
										<option value='Arkansas'>Arkansas</option>
										<option value='British Columbia'>British Columbia</option>
										<option value='California'>California</option>
										<option value='Colorado'>Colorado</option>
										<option value='Connecticut'>Connecticut</option>
										<option value='Delaware'>Delaware</option>
										<option value='District Of Columbia'>District Of Columbia</option>
										<option value='Florida'>Florida</option>
										<option value='Georgia'>Georgia</option>
										<option value='Hawaii'>Hawaii</option>
										<option value='Idaho'>Idaho</option>
										<option value='Illinois'>Illinois</option>
										<option value='Indiana'>Indiana</option>
										<option value='Iowa'>Iowa</option>
										<option value='Kansas'>Kansas</option>
										<option value='Kentucky'>Kentucky</option>
										<option value='Louisiana'>Louisiana</option>
										<option value='Maine'>Maine</option>
										<option value='Manitoba'>Manitoba</option>
										<option value='Maryland'>Maryland</option>
										<option value='Massachusetts'>Massachusetts</option>
										<option value='Michigan'>Michigan</option>
										<option value='Minnesota'>Minnesota</option>
										<option value='Mississippi'>Mississippi</option>
										<option value='Missouri'>Missouri</option>
										<option value='Montana'>Montana</option>
										<option value='Nebraska'>Nebraska</option>
										<option value='Nevada'>Nevada</option>
										<option value='Newfoundland'>Newfoundland</option>
										<option value='New Brunswick'>New Brunswick</option>
										<option value='New Hampshire'>New Hampshire</option>
										<option value='New Jersey'>New Jersey</option>
										<option value='New Mexico'>New Mexico</option>
										<option value='New York'>New York</option>
										<option value='North Carolina'>North Carolina</option>
										<option value='North Dakota'>North Dakota</option>
										<option value='Northwest Territories'>Northwest Territories</option>
										<option value='Nova Scotia'>Nova Scotia</option>
										<option value='Nunavut'>Nunavut</option>
										<option value='Ohio'>Ohio</option>
										<option value='Oklahoma'>Oklahoma</option>
										<option value='Ontario'>Ontario</option>
										<option value='Oregon'>Oregon</option>
										<option value='Pennsylvania'>Pennsylvania</option>
										<option value='Prince Edward Island'>Prince Edward Island</option>
										<option value='Quebec'>Quebec</option>
										<option value='Rhode Island'>Rhode Island</option>
										<option value='Saskatchewan'>Saskatchewan</option>
										<option value='South Carolina'>South Carolina</option>
										<option value='South Dakota'>South Dakota</option>
										<option value='Tennessee'>Tennessee</option>
										<option value='Texas'>Texas</option>
										<option value='Utah'>Utah</option>
										<option value='Vermont'>Vermont</option>
										<option value='Virginia'>Virginia</option>
										<option value='Washington'>Washington</option>
										<option value='West Virginia'>West Virginia</option>
										<option value='Wisconsin'>Wisconsin</option>
										<option value='Wyoming'>Wyoming</option>
										<option value='Yukon'>Yukon</option>
									</select>
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='province'>
										Shipping Province (for customers in Canada):
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='province' name='province' placeholder='Enter your province' {...register('province')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='zip_code'>
										Shipping Zip / Postal Code:
									</label>
									<input type='number' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='zip_code' name='zip_code' placeholder='Enter your zip / postal code' {...register('zip_code')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='country'>
										Shipping Country:
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='country' name='country' placeholder='Please select ...' {...register('country')}>
										<option value=''>Please select ...</option>
										<option value='CA'>Canada</option>
										<option value='US'>United States</option>
									</select>
								</div>

								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 mt-3 font-bold leading-normal'>Billing Information for Repaired/Returned Product</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='bl_address'>
										Billing Address:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='bl_address' name='bl_address' placeholder='Enter your address' {...register('bl_address')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='bl_address2'>
										Billing Address 2:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='bl_address2' name='bl_address2' placeholder='Enter your address line 2' {...register('bl_address2')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='bl_city'>
										Billing City:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='bl_city' name='bl_city' placeholder='Enter your city' {...register('bl_city')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='bl_state'>
										Billing State (for customers in the USA or PR):
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='bl_state' name='bl_state' placeholder='Please select ...' {...register('bl_state')}>
										<option value=''>Please select ...</option>
										<option value='Other'>Other</option>
										<option value='None'>None</option>
										<option value='Alaska'>Alaska</option>
										<option value='Alberta'>Alberta</option>
										<option value='Arizona'>Arizona</option>
										<option value='Arkansas'>Arkansas</option>
										<option value='British Columbia'>British Columbia</option>
										<option value='California'>California</option>
										<option value='Colorado'>Colorado</option>
										<option value='Connecticut'>Connecticut</option>
										<option value='Delaware'>Delaware</option>
										<option value='District Of Columbia'>District Of Columbia</option>
										<option value='Florida'>Florida</option>
										<option value='Georgia'>Georgia</option>
										<option value='Hawaii'>Hawaii</option>
										<option value='Idaho'>Idaho</option>
										<option value='Illinois'>Illinois</option>
										<option value='Indiana'>Indiana</option>
										<option value='Iowa'>Iowa</option>
										<option value='Kansas'>Kansas</option>
										<option value='Kentucky'>Kentucky</option>
										<option value='Louisiana'>Louisiana</option>
										<option value='Maine'>Maine</option>
										<option value='Manitoba'>Manitoba</option>
										<option value='Maryland'>Maryland</option>
										<option value='Massachusetts'>Massachusetts</option>
										<option value='Michigan'>Michigan</option>
										<option value='Minnesota'>Minnesota</option>
										<option value='Mississippi'>Mississippi</option>
										<option value='Missouri'>Missouri</option>
										<option value='Montana'>Montana</option>
										<option value='Nebraska'>Nebraska</option>
										<option value='Nevada'>Nevada</option>
										<option value='Newfoundland'>Newfoundland</option>
										<option value='New Brunswick'>New Brunswick</option>
										<option value='New Hampshire'>New Hampshire</option>
										<option value='New Jersey'>New Jersey</option>
										<option value='New Mexico'>New Mexico</option>
										<option value='New York'>New York</option>
										<option value='North Carolina'>North Carolina</option>
										<option value='North Dakota'>North Dakota</option>
										<option value='Northwest Territories'>Northwest Territories</option>
										<option value='Nova Scotia'>Nova Scotia</option>
										<option value='Nunavut'>Nunavut</option>
										<option value='Ohio'>Ohio</option>
										<option value='Oklahoma'>Oklahoma</option>
										<option value='Ontario'>Ontario</option>
										<option value='Oregon'>Oregon</option>
										<option value='Pennsylvania'>Pennsylvania</option>
										<option value='Prince Edward Island'>Prince Edward Island</option>
										<option value='Quebec'>Quebec</option>
										<option value='Rhode Island'>Rhode Island</option>
										<option value='Saskatchewan'>Saskatchewan</option>
										<option value='South Carolina'>South Carolina</option>
										<option value='South Dakota'>South Dakota</option>
										<option value='Tennessee'>Tennessee</option>
										<option value='Texas'>Texas</option>
										<option value='Utah'>Utah</option>
										<option value='Vermont'>Vermont</option>
										<option value='Virginia'>Virginia</option>
										<option value='Washington'>Washington</option>
										<option value='West Virginia'>West Virginia</option>
										<option value='Wisconsin'>Wisconsin</option>
										<option value='Wyoming'>Wyoming</option>
										<option value='Yukon'>Yukon</option>
									</select>
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='bl_province'>
										Province (for customers in Canada):
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='bl_province' name='bl_province' placeholder='Enter your province' {...register('bl_province')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='bl_zip_code'>
										Billing Zip / Postal Code:
									</label>
									<input type='number' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='bl_zip_code' name='bl_zip_code' placeholder='Enter your zip / postal code' {...register('bl_zip_code')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='bl_country'>
										Billing Country:
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='bl_country' name='bl_country' placeholder='Please select ...' {...register('bl_country')}>
										<option value=''>Please select ...</option>
										<option value='CA'>Canada</option>
										<option value='US'>United States</option>
									</select>
								</div>

								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 mt-3 font-bold leading-normal'>Order Information - please provide one of the numbers below</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='invoice_number'>
										Invoice Number:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='invoice_number' name='invoice_number' placeholder='Enter your invoice number' {...register('invoice_number')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='packing_slip'>
										Packing Slip Number:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='packing_slip' name='packing_slip' placeholder='Enter your packing slip number' {...register('packing_slip')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='sales_order'>
										Sales Order Number:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='sales_order' name='sales_order' placeholder='Enter your sales order number' {...register('sales_order')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='purchase_order'>
										Purchase Order:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='purchase_order' name='purchase_order' placeholder='Enter your purchase order number' {...register('purchase_order')} />
								</div>

								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 mt-3 font-bold leading-normal'>Item 1</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_1_name'>
										Name:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_1_name' name='item_1_name' placeholder='Enter name of item 1' {...register('item_1_name')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4 relative'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_1_sku'>
										SKU: <span className='text-red-500'>*</span>
									</label>
									<input type='text' className={`w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none ${errors?.item_1_sku ? 'border-red-400 focus:border-red-400' : 'border-gray-300'}`} placeholder='Enter SKU of item 1' {...register('item_1_sku', { required: true })} />
									{errors?.item_1_sku && <p className='absolute right-4 text-red-500'>required</p>}
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_1_qty'>
										Quantity:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_1_qty' name='item_1_qty' placeholder='Enter Qty of item 1' {...register('item_1_qty')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_1_warrenty'>
										Under Warranty:
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_1_warrenty' name='item_1_warrenty' placeholder='Please select ...' {...register('item_1_warrenty')}>
										<option value=''>Please select ...</option>
										<option value='Yes'>Yes</option>
										<option value='No'>No</option>
										<option value='Unknown'>Unknown</option>
									</select>
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4 relative'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_1_issue'>
										Issue: <span className='text-red-500'>*</span>
									</label>
									<input
										type='text'
										className={`w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none ${errors?.item_1_issue ? 'border-red-400 focus:border-red-400' : 'border-gray-300'}`}
										placeholder='Enter issue of item 1'
										{...register('item_1_issue', { required: true })}
									/>
									{errors?.item_1_issue && <p className='absolute right-4 text-red-500'>required</p>}
								</div>

								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 mt-3 font-bold leading-normal'>Item 2</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_2_name'>
										Name:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_2_name' name='item_2_name' placeholder='Enter name of item 2' {...register('item_2_name')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_2_sku'>
										SKU:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_2_sku' name='item_2_sku' placeholder='Enter SKU of item 2' {...register('item_2_sku')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_2_qty'>
										Quantity:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_2_qty' name='item_2_qty' placeholder='Enter Qty of item 2' {...register('item_2_qty')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_2_warrenty'>
										Under Warranty:
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_2_warrenty' name='item_2_warrenty' placeholder='Please select ...' {...register('item_2_warrenty')}>
										<option value=''>Please select ...</option>
										<option value='Yes'>Yes</option>
										<option value='No'>No</option>
										<option value='Unknown'>Unknown</option>
									</select>
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_2_issue'>
										Issue:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_2_issue' name='item_2_issue' placeholder='Enter issue of item 2' {...register('item_2_issue')} />
								</div>

								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 mt-3 font-bold leading-normal'>Item 3</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_3_name'>
										Name:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_3_name' name='item_3_name' placeholder='Enter name of item 3' {...register('item_3_name')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_3_sku'>
										SKU:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_3_sku' name='item_3_sku' placeholder='Enter SKU of item 3' {...register('item_3_sku')} />
								</div>
								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_3_qty'>
										Quantity:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_3_qty' name='item_3_qty' placeholder='Enter Qty of item 3' {...register('item_3_qty')} />
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_3_warrenty'>
										Under Warranty:
									</label>
									<select className='w-full !h-[50px] !bg-white mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_3_warrenty' name='item_3_warrenty' placeholder='Please select ...' {...register('item_3_warrenty')}>
										<option value=''>Please select ...</option>
										<option value='Yes'>Yes</option>
										<option value='No'>No</option>
										<option value='Unknown'>Unknown</option>
									</select>
								</div>

								<div className='wrapper flex flex-col py-4 px-0 sm:px-4'>
									<label className='relative block font-semibold leading-normal' htmlFor='item_3_issue'>
										Issue:
									</label>
									<input type='text' className='w-full mt-2 p-3 border border-solid border-gray-300 rounded-lg focus:outline-none' id='item_3_issue' name='item_3_issue' placeholder='Enter issue of item 3' {...register('item_3_issue')} />
								</div>

								<h1 className='text-[20px] sm:text-2xl px-0 sm:px-4 mt-3 font-bold leading-normal'>Decontamination Check</h1>
								<div className='border-dashed mx-0 sm:mx-4 border-opacity-100 border-2 border-gray-300 mb-4'></div>
								<div className='px-0 sm:px-4'>
									<p className='text-[15px] leading-normal mb-4 md:text-justify'>We will not accept delivery of any equipment that contains or is contaminated with hazardous substances. Please make sure all items are properly cleaned and decontaminated prior to returning to our facility.</p>
									<p className='text-[15px] leading-normal mb-4 md:text-justify'>Decontaminating your equipment can be done by the following methods:</p>
									<ul className='m-4 px-4 list-disc leading-normal md:text-justify text-[15px]'>
										<li>Gas Sterilization (Ethylene Oxide)</li>
										<li>Irradiation</li>
										<li>Disinfectant wipe down (i.e. 10% Bleach Solution)</li>
										<li>Scintillation count (For radioactive applications. Please include isotope data.)</li>
									</ul>
									<p className='text-[15px] leading-normal mb-4 md:text-justify'>Please mark the following check boxes to confirm that your return is safe to handle.</p>
									<h2 className='font-bold mb-3'>
										<strong>Hazardous Material:</strong>
									</h2>
									<div className='text-[15px] leading-normal flex items-start'>
										<input type='checkbox' className='mr-2 mt-1' value='Y' name='not_radioactive' id='not_radioactive' {...register('not_radioactive', { required: true })} />
										<label className='relative md:text-justify' htmlFor='not_radioactive'>
											The returned product is not radioactive.
										</label>
									</div>
									<div className='text-[15px] leading-normal flex items-start'>
										<input type='checkbox' className='mr-2 mt-1' value='Y' name='not_bioactive' id='not_bioactive' {...register('not_bioactive', { required: true })} />
										<label className='relative md:text-justify' htmlFor='not_bioactive'>
											The returned product is not biologically active
										</label>
									</div>
									<div className='text-[15px] leading-normal flex items-start'>
										<input type='checkbox' className='mr-2 mt-1' value='Y' name='not_dangerous' id='not_dangerous' {...register('not_dangerous', { required: true })} />
										<label className='relative md:text-justify' htmlFor='not_dangerous'>
											The returned product is not dangerous to human health and safety.
										</label>
									</div>

									<small className='text-[80%]'>NOTE: Not checking the boxes may cause that an RMA number assignment not to be issued</small>
									<div className='relative h-6'>{(errors?.not_radioactive || errors?.not_bioactive || errors?.not_dangerous) && <p className='text-red-500 mt-2 ms-3 absolute top-0'>Please accept the terms and conditions.</p>}</div>
								</div>

								<div className='recaptcha py-4 px-0 sm:px-4'>
									<ReCAPTCHA className={Style.gRecaptchaa} sitekey='6LfcLOIUAAAAALCab8T2PSUyLVSqwa0sPfpSqLHM' onChange={onChangeCaptcha} ref={recaptcha} />
									{isVmessage ? <p className='text-red-500 mt-2'>Please complete the reCAPTCHA</p> : ''}
								</div>
								<div className='px-0 sm:px-4'>
									{/* <button type='submit' className='btn px-8 py-4 font-semibold tracking-widest rounded-full relative overflow-hidden mt-6 mb-10 md:mb-20 dark-blue-bg text-white'>
										Submit
									</button> */}
									<button type='submit' disabled={Loading} className={`${cursor}btn px-8 py-4 font-semibold tracking-widest rounded-full relative overflow-hidden mt-6 mb-10 md:mb-20 dark-blue-bg text-white`}>
										{Loading ? (
											<div className='flex items-center'>
												Submit
												<LiteLoader loaderType='sml' className='ml-[5px]' />
											</div>
										) : (
											'Submit'
										)}
									</button>
								</div>
								<input type='hidden' name='save' value='yes' />
							</form>
						</section>
					) : (
						''
					)}
				</div>
			</div>
			<div className={`alert-message ${successMessage?.type == 'error' ? 'error' : ''}${successMessage?.type == 'success' || successMessage?.type == 'error' ? '' : 'hidden'}`}>{successMessage?.message}</div>
		</>
	)
}

export default Detail
