import { imgApiUrl } from '../../../../lib/constants'
import { MainContext } from '../../../../contexts/MainContext'
import { useContext } from 'react'
import Image from 'next/dist/client/image'
import Link from 'next/link'
import style from '../Category.module.css'

const CategoryProduct = (props: any) => {
	const { setIsLoading } = useContext(MainContext)
	let image = props?.product?.files?.length > 0 && props?.product?.files[0]?.type == 'images' ? `${imgApiUrl?.products?.thumbnail}/${props?.product?.files[0]?.name}` : '/assets/img/thumbnail-no-img.jpg'
	//
	const apiDate = props?.product?.price_discounted_end

	const discountedEndDate = apiDate ? new Date(apiDate) : null

	const Dis_percent = ((props?.product?.price_catalog - props?.product?.price_discounted) / props?.product?.price_catalog) * 100
	const price =
		props?.product?.price_discounted != null && props?.product?.price_discounted != 0 && (discountedEndDate === null || discountedEndDate >= new Date()) ? (
			<>
				<span className='new-price mr-2 font-bold'>${props?.product?.price_discounted?.toFixed(2)}</span>
				<span className='old-price mr-2 text-xs text-[#4a4949] line-through'>${props?.product?.price_catalog?.toFixed(2)}</span>
				<span className='old-price text-xs text-red-500 font-bold w-max'>{Dis_percent?.toFixed(2)}% Off</span>
			</>
		) : props?.product?.price < props?.product?.price_catalog ? (
			<span className='new-price mr-2 font-bold'>${props?.product?.price?.toFixed(2)}</span>
		) : (
			<span className='new-price font-bold'>{props?.product?.price_catalog ? `$${props?.product?.price_catalog?.toFixed(2)}` : 'Multiple SKUs, Click For Details'}</span>
		)
	//
	return (
		<Link href={`/${props?.product?.slug}`}>
			<a className='category-product-detail relative flex border-t border-solid border-gray-300 justify-between items-start  sm:items-center p-3 w-full text-sm cursor-pointer' onClick={() => setIsLoading(true)}>
				<div className='category-product-img absolute top-0 left-0 z-10 shadow-lg rounded-lg w-3/12'>
					<Image layout='responsive' width={198} height={198} className='rounded-lg' src={image} alt={props?.product?.files?.length > 0 ? props?.product?.name : ''} lazyBoundary='400px' />
				</div>
				<div className={`${style.categoryProductDetailWrapper} flex items-center pr-2 w-full`}>
					<span className='sku'>{props?.product?.sku}</span>
					<span className='category-product-title w-full ml-2'>{props?.product?.name}</span>
				</div>
				<span className=' price cat-price text-sm ml-2 flex justify-end items-center'>{price}</span>
			</a>
		</Link>
	)
}

export default CategoryProduct
